import { Collapse, Group, Paper } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useState, lazy, Suspense, useEffect } from "react";
import RoomSkeleton from "./RoomSkeleton";

const Room = lazy(() => import("./Room"));

export default function Floor({
  floor,
  children,
  accommodation,
  setAccommodation,
  childrenHandlers,
  accommodationType,
  messages,
  searching,
}) {
  const [opened, setOpened] = useState(false);
  const [roomsLoaded, setRoomsLoaded] = useState(false);

  const handleToggle = () => {
    setOpened((prevOpened) => {
      if (!prevOpened && !roomsLoaded) {
        setRoomsLoaded(true);
      }
      return !prevOpened;
    });
  };

  useEffect(() => {
    if (searching && !opened) {
      handleToggle();
    }
  }, [searching]);

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={handleToggle}>
        <Group justify="space-between">
          <h2>{floor.name}</h2>
          {opened ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
        </Group>
      </Paper>
      <Collapse in={opened}>
        {roomsLoaded && (
          <div className="mb-5 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
            <Suspense
              fallback={floor.rooms.map((room, i) => (
                <RoomSkeleton key={i} roomPesonCount={room.capacity} title={room.name} />
              ))}>
              {floor.rooms.map((room) => (
                <Room
                  accommodationType={accommodationType}
                  messages={messages}
                  accommodation={accommodation}
                  key={room.id}
                  childrenHandlers={childrenHandlers}
                  setAccommodation={setAccommodation}
                  children={children}
                  room={room}
                />
              ))}
            </Suspense>
          </div>
        )}
      </Collapse>
    </>
  );
}
