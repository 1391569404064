import classNames from "classnames";
import useTitle from "../../hooks/useTitle.js";
import { Button, Collapse, Grid, Group, Loader, Paper, Select, Stack, Text, TextInput } from "@mantine/core";
import { useRef, useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  IconArrowsMinimize,
  IconBriefcase2,
  IconCheck,
  IconClock,
  IconEye,
  IconEyeOff,
  IconFilter,
  IconHorseToy,
  IconMaximize,
  IconSearch,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import { useLoaderData } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { useFullscreen, useListState } from "@mantine/hooks";
import Floor from "../../components/accommodation/boarding/Floor";
import { toast } from "react-hot-toast";
import Page from "../../components/layout/Page";
import PeopleList from "../../components/accommodation/PeopleList.jsx";
import { usePermissions } from "../../providers/PermissionsProvider.jsx";

export async function loader({ params }) {
  return Promise.all([useFetch(`accommodation`), useFetch(`/children/list/accommodation`), useFetch(`/staff`)]).then(
    ([accommodation, children, staff]) => {
      return { accommodation, children, staff };
    }
  );
}

export default function BoardingPage() {
  const accommodationData = useLoaderData().accommodation.data;
  const childrenData = useLoaderData().children.data;
  const staffData = useLoaderData().staff.data;
  const { hasPermission } = usePermissions();

  const [accommodation, setAccommodation] = useState(accommodationData);
  const rawAccommodation = useRef(accommodationData);
  const [roomType, setRoomType] = useState("room");
  const [children, childrenHandlers] = useListState(childrenData);
  const [staff, staffHandlers] = useListState(staffData);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [staffFiltered, setStaffFiltered] = useState(() => {
    return staffData.sort((staffA, staffB) => {
      return staffA.lastName.localeCompare(staffB.lastName);
    });
  });
  const [childrenFiltered, setChildrenFiltered] = useState(() => {
    return childrenData.sort((childA, childB) => {
      return childA.lastName.localeCompare(childB.lastName);
    });
  });
  const [filters, setFilters] = useState({
    gender: "",
    team: "",
  });

  const [search, setSearch] = useState("");
  const [roomsSearch, setRoomsSearch] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [accommodationType, setAccommodationType] = useState("accommodate");
  const [showSidebar, setShowSidebar] = useState(true);
  const { toggle, fullscreen } = useFullscreen();

  useEffect(() => {
    const resetAccommodation = rawAccommodation.current.map((floor) => ({
      ...floor,
      rooms: floor.rooms.filter((room) => room.type === roomType),
    }));

    const filteredAccommodation = resetAccommodation.map((floor) => {
      if (roomsSearch === "") {
        return floor;
      }

      const filteredRooms = [];
      floor.rooms.forEach((room) => {
        room.occupation.forEach((person) => {
          if (!person.firstName || !person.lastName) return;

          const fullName = `${person.firstName.toLowerCase()} ${person.lastName.toLowerCase()}`;
          if (fullName.includes(roomsSearch.toLowerCase())) {
            if (!filteredRooms.some((fRoom) => fRoom.id === room.id)) {
              filteredRooms.push(room);
            }
          }
        });
      });

      return {
        ...floor,
        rooms: filteredRooms,
      };
    });

    setAccommodation(filteredAccommodation);
  }, [roomType, roomsSearch]);

  const actionButtons = [
    {
      name: fullscreen ? "Zmenšit" : "Zvětšit na celou obrazovku",
      onTrigger: () => {
        toggle();
      },
      icon: fullscreen ? IconArrowsMinimize : IconMaximize,
    },
    {
      name: showSidebar ? "Schovat lištu" : "Zobrazit lištu",
      onTrigger: () => {
        setShowSidebar(!showSidebar);
      },
      icon: showSidebar ? IconEyeOff : IconEye,
      primary: !!showSidebar,
    },
  ];

  useEffect(() => {
    setChildrenFiltered(children);
    setSearch("");
    setListLoading(false);
  }, [children]);

  useEffect(() => {
    setStaffFiltered(staff);
    setSearch("");
    setListLoading(false);
  }, [staff]);

  useTitle(`Přiřazování pokojů`);

  const onStaffDragEnd = async (result) => {
    if (
      !result.destination ||
      result.destination.droppableId === result.source.droppableId ||
      result.destination.droppableId === "staffList"
    ) {
      return;
    }

    setListLoading(true);

    const room = Number(result.destination.droppableId.split("-")[1]);
    const staffMemberID = Number(result.draggableId);

    const accommodationCopy = [...accommodation];

    const floorIndex = accommodationCopy.findIndex((floor) => floor.rooms.find((roomLocal) => roomLocal.id === room));

    const floor = accommodationCopy.splice(floorIndex, 1);

    const staffMember = staff.find((staffLocal) => staffLocal.id === staffMemberID);

    if (staffMember.roomName !== null) {
      toast.error("Vedoucí je již ubytován");
      setListLoading(false);
      return;
    }

    await floor[0].rooms.forEach((roomLocal) => {
      if (roomLocal.id === room) {
        if (roomLocal.occupation.filter((staffMemberLocal) => staffMemberLocal.id).length >= roomLocal.capacity) {
          toast.error("Tento pokoj je již obsazený.");
          setListLoading(false);
          return;
        }
        let isAccommodated = false;

        useFetch(`/staff/${staffMemberID}/accommodate`, "POST", {
          room_id: room,
        }).then((res) => {});
        roomLocal.occupation.forEach((staffMemberLocal, index) => {
          if (!staffMemberLocal.id && !isAccommodated) {
            isAccommodated = true;
            roomLocal.occupation[index] = staffMember;
          }
        });
      }
    });

    staffHandlers.applyWhere(
      (staffMember) => staffMember.id === staffMemberID,
      (staffMember) => ({ ...staffMember, roomName: room })
    );

    setAccommodation([...floor, ...accommodationCopy].sort((a, b) => a.id - b.id));
  };

  const onChildDragEnd = async (result) => {
    if (
      !result.destination ||
      result.destination.droppableId === result.source.droppableId ||
      result.destination.droppableId === "childList"
    ) {
      return;
    }

    setListLoading(true);

    let room = Number(result.destination.droppableId.split("-")[1]);
    let kid = Number(result.draggableId);

    let accommodationCopy = [...accommodation];

    let floorIndex = accommodationCopy.findIndex((floor) => floor.rooms.find((roomLocal) => roomLocal.id === room));

    let floor = accommodationCopy.splice(floorIndex, 1);

    let canAccommodate = false;

    let child = children.find((child) => child.id === kid);

    await floor[0].rooms.forEach((roomLocal) => {
      if (roomLocal.id === room) {
        let roomGender = null;
        roomLocal.occupation.forEach((kidLocal) => {
          if (kidLocal.id) {
            roomGender = kidLocal.gender;
          }
        });

        if (child.roomReservation) {
          toast.error("Dítě již má rezervaci pokoje.");
          setListLoading(false);
          return;
        }

        if (roomGender && roomGender !== child.gender) {
          toast.error("Do tohoto pokoje nelze ubytovat dítě jiného pohlaví");
          setListLoading(false);
          return;
        }

        if (roomLocal.occupation.filter((kidLocal) => kidLocal.id).length >= roomLocal.capacity) {
          toast.error("Tento pokoj je již obsazený.");
          setListLoading(false);
          return;
        }
        canAccommodate = true;
        let isAccommodated = false;
        useFetch(`/children/${kid}/accommodate`, "POST", {
          room_id: room,
          reservation: accommodationType === "reserve",
        }).then((res) => {
          if (res.status === "ok") {
            toast.success(accommodationType === "reserve" ? "Místo pro dítě rezervováno." : "Dítě ubytováno");
          }
        });

        roomLocal.occupation.forEach((kidLocal, index) => {
          if (!kidLocal.id && !isAccommodated) {
            isAccommodated = true;
            roomLocal.occupation[index] = child;
          }
        });
      }
    });

    if (canAccommodate) {
      childrenHandlers.applyWhere(
        (child) => child.id === kid,
        (child) => ({ ...child, isAccommodated: true, roomReservation: accommodationType === "reserve" })
      );

      setAccommodation([...floor, ...accommodationCopy].sort((a, b) => a.id - b.id));
    }
  };

  const messages = {
    staff: {
      adding: "Přiřazuji vedoucího...",
      notFound: "Žádný vedoucí nenalezen",
      searchAccommodated: "Hledat ubytovaného vedoucího...",
      find: "Najít vedoucího",
      unaccommodated: "Vedoucí vystěhován",
      accommodated: "Vedoucí ubytován",
      spotReserved: "Místo pro vedoucího rezervováno",
      choose: "Vyber vedoucího...",
      search: "Hledat vedoucího v seznamu...",
      noone: "Žádný ubytovaný vedoucí",
    },
    room: {
      adding: "Přiřazuji dítě...",
      notFound: "Žádné dítě nenalezeno",
      searchAccommodated: "Hledat ubytovaného dítě...",
      find: "Najít dítě",
      unaccommodated: "Dítě vystěhováno",
      accommodated: "Dítě ubytováno",
      spotReserved: "Místo pro dítě rezervováno",
      choose: "Vyber dítě...",
      search: "Hledat dítě v seznamu...",
      noone: "Žádné ubytované dítě",
    },
  };

  useEffect(() => {
    setChildrenFiltered(
      children.filter((child) => `${child.firstName} ${child.lastName}`.toLowerCase().includes(search.toLowerCase()))
    );
    setStaffFiltered(
      staff.filter((staff) => `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search]);

  return (
    <Page title="Přiřazení pokojů" actionIcons={actionButtons}>
      <DragDropContext onDragEnd={roomType === "room" ? onChildDragEnd : onStaffDragEnd}>
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              placeholder={messages[roomType].searchAccommodated}
              mb="md"
              onChange={(event) => setRoomsSearch(event.currentTarget.value)}
              value={roomsSearch}
              leftSection={<IconSearch stroke={1.5} size={16} />}
              onFocus={(e) => e.target.select()}
            />
            {Array.isArray(accommodation) &&
              accommodation.map(
                (floor) =>
                  (roomsSearch === "" ||
                    floor.rooms.filter(
                      (room) =>
                        room.occupation.filter((occupation) =>
                          `${occupation?.firstName} ${occupation?.lastName}`
                            ?.toLowerCase()
                            .includes(roomsSearch.toLowerCase())
                        ).length > 0
                    ).length > 0) && (
                    <Floor
                      accommodationType={accommodationType}
                      setAccommodation={setAccommodation}
                      childrenHandlers={roomType === "room" ? childrenHandlers : staffHandlers}
                      accommodation={accommodation}
                      children={roomType === "room" ? children : staff}
                      key={floor.id}
                      floor={floor}
                      messages={messages[roomType]}
                      searching={roomsSearch !== ""}
                    />
                  )
              )}
          </Grid.Col>
          {showSidebar && (
            <Grid.Col span={3} className={classNames("hidden lg:block")}>
              <Paper p="md" className="mb-4" withBorder radius="lg">
                {accommodationType === "reserve" ? (
                  <Button
                    leftSection={<IconClock stroke={1.5} size="18" />}
                    variant="filled"
                    color="orange"
                    mb="sm"
                    fullWidth
                    onClick={() => setAccommodationType("accommodate")}>
                    Rezervace
                  </Button>
                ) : (
                  <Button
                    leftSection={<IconCheck stroke={1.5} size="18" />}
                    variant="filled"
                    color="green"
                    fullWidth
                    mb="sm"
                    disabled={roomType === "staff"}
                    onClick={() => setAccommodationType("reserve")}>
                    Ubytování
                  </Button>
                )}
                {hasPermission("staff.boarding") &&
                  (roomType === "room" ? (
                    <Button
                      leftSection={<IconHorseToy stroke={1.5} size="18" />}
                      variant="light"
                      fullWidth
                      mb="sm"
                      onClick={() => {
                        setRoomType("staff");
                        setAccommodationType("accommodate");
                      }}>
                      Děti
                    </Button>
                  ) : (
                    <Button
                      leftSection={<IconBriefcase2 stroke={1.5} size="18" />}
                      variant="filled"
                      color="red"
                      fullWidth
                      mb="sm"
                      onClick={() => setRoomType("room")}>
                      Vedoucí
                    </Button>
                  ))}
                <Group gap="xs" mb="sm">
                  <Button
                    leftSection={<IconFilter stroke={1.5} size="18" />}
                    variant={filtersOpened ? "filled" : "light"}
                    className="hidden grow basis-16"
                    onClick={() => setFiltersOpened(!filtersOpened)}>
                    Filtry
                  </Button>
                  <Button
                    leftSection={
                      sortBy === "name" || sortBy === "age" ? (
                        <IconSortDescending stroke={1.5} size="18" />
                      ) : (
                        <IconSortAscending stroke={1.5} size="18" />
                      )
                    }
                    variant="light"
                    className="grow basis-16"
                    onClick={() => (sortBy === "name" ? setSortBy("age") : setSortBy("name"))}>
                    {sortBy === "name" ? "Jméno" : sortBy === "age" ? "Věk" : ""}
                  </Button>
                </Group>
                <Collapse in={filtersOpened}>
                  <div className="mb-4">
                    <Select
                      label="Pohlaví"
                      placeholder="Vyber pohlaví"
                      className="mb-1"
                      onChange={(value) => setFilters({ ...filters, gender: value })}
                      value={filters.gender}
                      data={[
                        {
                          value: "Chlapec",
                          label: "Chlapec",
                        },
                        {
                          value: "Dívka",
                          label: "Dívka",
                        },
                        {
                          value: "",
                          label: "Všechny",
                        },
                      ]}
                    />
                  </div>
                </Collapse>
                <TextInput
                  placeholder={messages[roomType].search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  value={search}
                  leftSection={<IconSearch stroke={1.5} size={16} />}
                  onFocus={(e) => e.target.select()}
                />
                {listLoading ? (
                  <Stack mt="lg" className="text-center" gap="xs" align="center">
                    <Loader />
                    <Text c="dimmed">{messages[roomType].adding}</Text>
                  </Stack>
                ) : roomType === "room" ? (
                  childrenFiltered.length > 0 ? (
                    <PeopleList people={childrenFiltered} type={roomType} sortBy={sortBy} />
                  ) : (
                    <Stack align="center" gap={0} mt="lg" className="text-center">
                      <Text className="text-4xl">😿</Text>
                      <Text c="dimmed">{messages[roomType].notFound}</Text>
                    </Stack>
                  )
                ) : staffFiltered.length > 0 ? (
                  <PeopleList people={staffFiltered} type={roomType} sortBy={sortBy} />
                ) : (
                  <Stack align="center" gap={0} mt="lg" className="text-center">
                    <Text className="text-4xl">😿</Text>
                    <Text c="dimmed">{messages[roomType].notFound}</Text>
                  </Stack>
                )}
              </Paper>
            </Grid.Col>
          )}
        </Grid>
      </DragDropContext>
    </Page>
  );
}
